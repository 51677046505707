import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  FeatureImage,
  FeatureImageHero,
  FeatureImageHeroMobile,
  ContactForm,
  H2,
  Button,
  Container
} from "../components/styled/components"
import { useMediaQuery } from "react-responsive"
import NumberFormat from "react-number-format"

const ContactPage = ({ data }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

  return (
    <Layout>
      <SEO title="Contact Us | WellHealth" />
      {isTabletOrMobile ?
      <FeatureImageHeroMobile>
        <H2 fontSize="40px" textAlign="center">
          Contact Us
        </H2>
        <Img fluid={data.photo1.childImageSharp.fluid} alt="Contact Us"/>
      </FeatureImageHeroMobile> :
      <FeatureImageHero>
        <section>
          <h2>
            Contact Us
          </h2>
        </section>
        <FeatureImage>
          <Img fluid={data.photo1.childImageSharp.fluid} alt="Contact Us"/>
        </FeatureImage>
      </FeatureImageHero>}
      <Container
        textAlign="center"
        css={{ marginBottom: "100px", paddingTop: "40px" }}
      >
        <ContactForm
          name="Contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <H2 mb={2} fontSize="52px" textAlign="left">
            Contact Us
          </H2>
          <H2 mb={2} fontSize="24px" textAlign="left" style={{ fontFamily: "Gotham", fontWeight: "normal" }}>
            Or call us at <a href="tel:+12818176060" style={{ color: "#007AFF" }}>(281) 817-6060</a>
          </H2>
          <input type="hidden" name="form-name" value="Contact" />
          <p hidden>
            <label>
              Don’t fill this out: <input name="bot-field" />
            </label>
          </p>
          <div>
            <input
              placeholder="First Name"
              aria-label="First Name"
              type="text"
              name="firstName"
              required
            />
          </div>
          <div>
            <input
              placeholder="Last Name"
              aria-label="Last Name"
              type="text"
              name="lastName"
              required
            />
          </div>
          <div>
            <input
              placeholder="Email"
              aria-label="Email"
              type="email"
              name="email"
              required
            />
          </div>
          <div>
            <NumberFormat
              placeholder="Phone Number"
              aria-label="Phone Number"
              type="tel"
              name="phone"
              format="(###) ###-####"
              mask="_"
              required
            />
          </div>
          <div>
            <input
              placeholder="Organization"
              aria-label="Organization"
              type="text"
              name="organization"
              required
            />
          </div>
          <div>
            <input
              placeholder="Message"
              aria-label="Message"
              type="text"
              name="Message"
              style={{ height: "100px" }}
              required
            />
          </div>
          <Button mt={3} type="submit">
            Submit
          </Button>
        </ContactForm>
      </Container>
    </Layout>
  )  
}

export default ContactPage

export const query = graphql`
  query ContactPageQuery {
    photo1: file(relativePath: { eq: "contact_page_hero.png" }) {
      childImageSharp {
        fluid(maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
